const _getHeader = () => {
  const header = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
  return header;
};

export default async function APICaller(
  endpoint
) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}${endpoint}`,
      {
        headers: _getHeader(),
        method: 'GET'
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
  }
}
